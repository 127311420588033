.privacy-policy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100vh;
}

.privacy-policy-header img {
  width: 100px;
}

.privacy-policy-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 1100px;
}

.privacy-policy-header p {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0px;
}

.privacy-policy-main p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0px;
}

.privacy-policy-main-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0px;
}
